import React, { useEffect, useState } from "react";
import { useGoogleLogin } from "@react-oauth/google";

const Login = () => {
  const [user, setUser] = useState();
  const [authStatus, setAuthStatus] = useState("");
  const [uploadStatus, setUploadStatus] = useState("");
  const [file, setFile] = useState(null);

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      setUser(codeResponse);
      const { code } = codeResponse;
      makeAPICall(code);
    },
    onError: (error) => console.log("Login Failed:", error),
    flow: "auth-code",
    scope: [
      "https://www.googleapis.com/auth/gmail.readonly",
      "https://www.googleapis.com/auth/gmail.modify",
      "https://www.googleapis.com/auth/pubsub",
      "https://www.googleapis.com/auth/userinfo.profile",
      "https://www.googleapis.com/auth/userinfo.email",
      "https://www.googleapis.com/auth/gmail.settings.basic",
      "openid",
    ].join(" "),
  });

  const makeAPICall = async (code) => {
    // Send the authorization code to your backend
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/store-tokens/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ code }),
        }
      );

      const data = await res.json();
      console.log("Backend response:", data);
      if (data?.pubSubStatus == true)
        setAuthStatus("Login Success and pub sub created");
      else {
        setAuthStatus("Login Success but pub sub not created");
      }
    } catch (e) {
      setAuthStatus("Login Failed");
      // console.log(e.json());
    }
    setTimeout(() => {
      setAuthStatus("");
    }, 10000);
  };

  const handleFileUpload = async () => {
    if (!file) {
      setUploadStatus("Please select a file first");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/upload-leads/`,
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      setUploadStatus(data.message || "Upload completed");
      setFile(null); // Reset file input

      // Clear the file input value
      const fileInput = document.getElementById('fileInput');
      if (fileInput) fileInput.value = '';

    } catch (error) {
      setUploadStatus("Upload failed: " + error.message);
    }

    // Clear status after 5 seconds
    setTimeout(() => {
      setUploadStatus("");
    }, 5000);
  };

  return (
    <div>
      <button onClick={() => login()}>Sign in with Google 🚀 </button>

      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <input
          type="file"
          id="fileInput"
          accept=".csv"
          onChange={(e) => setFile(e.target.files[0])}
          style={{ marginRight: '10px' }}
        />
        <button
          onClick={handleFileUpload}
          disabled={!file}
        >
          Upload
        </button>
        {uploadStatus && (
          <p style={{ color: uploadStatus.includes('failed') ? 'red' : 'green' }}>
            {uploadStatus}
          </p>
        )}
      </div>

      <h3>Note:</h3>
      <p>
        If you are workspace admin then kindly add our app in your workspace by
        going to Google Admin
      </p>
      <p>
        <strong>Client ID:</strong>
        {process.env.REACT_APP_OAUTH2_CLIENT_ID}
      </p>
      <hr />
      {authStatus && (
        <>
          <p style={{ fontSize: "24px", color: "blue" }}>{authStatus}</p>
          <hr />
        </>
      )}
    </div>
  );
};

export default Login;
